var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-vehicle" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "终端名称：", prop: "deviceName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入终端名称" },
                model: {
                  value: _vm.addForm.deviceName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "deviceName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.deviceName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "终端厂家：", prop: "makerId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择终端厂家" },
                  model: {
                    value: _vm.addForm.makerId,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "makerId", $$v)
                    },
                    expression: "addForm.makerId"
                  }
                },
                _vm._l(_vm.producerList, function(i) {
                  return _c("el-option", {
                    key: i.dictCode,
                    attrs: { label: i.dictValue, value: i.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "终端型号：", prop: "model" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择终端型号" },
                  model: {
                    value: _vm.addForm.model,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "model", $$v)
                    },
                    expression: "addForm.model"
                  }
                },
                _vm._l(_vm.modelList, function(i) {
                  return _c("el-option", {
                    key: i.dictCode,
                    attrs: { label: i.dictValue, value: i.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "终端类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择终端类型" },
                  model: {
                    value: _vm.addForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "type", $$v)
                    },
                    expression: "addForm.type"
                  }
                },
                _vm._l(_vm.typeList, function(i) {
                  return _c("el-option", {
                    key: i.dictCode,
                    attrs: { label: i.dictValue, value: i.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "写卡模板：", prop: "cardTemplate" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6 },
                  maxlength: "200",
                  "show-word-limit": "",
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.addForm.cardTemplate,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "cardTemplate",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.cardTemplate"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }