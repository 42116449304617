
<template>
  <div class="body add-vehicle">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <el-form-item label="终端名称：" prop="deviceName">
        <el-input
          v-model.trim="addForm.deviceName"
          placeholder="请输入终端名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="终端厂家：" prop="makerId">
        <el-select
          v-model="addForm.makerId"
          clearable
          placeholder="请选择终端厂家"
        >
          <el-option
            v-for="i in producerList"
            :label="i.dictValue"
            :value="i.dictCode"
            :key="i.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="终端型号：" prop="model">
        <el-select
          v-model="addForm.model"
          clearable
          placeholder="请选择终端型号"
        >
          <el-option
            v-for="i in modelList"
            :label="i.dictValue"
            :value="i.dictCode"
            :key="i.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="终端类型：" prop="type">
        <el-select
          v-model="addForm.type"
          clearable
          placeholder="请选择终端类型"
        >
          <el-option
            v-for="i in typeList"
            :label="i.dictValue"
            :value="i.dictCode"
            :key="i.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="写卡模板：" prop="cardTemplate">
        <el-input
          type="textarea"
          :autosize="{ minRows: 6 }"
          maxlength="200"
          show-word-limit
          placeholder="请输入内容"
          v-model.trim="addForm.cardTemplate"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  addDeviceModel,
  editDeviceModel,
  queryDictsByCodes
} from '@/api/lib/api.js'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isEdit: false,
      addForm: {
        deviceName: '',
        makerId: '',
        model: '',
        type: '',
        cardTemplate: ''
      },
      rules: {
        deviceName: [
          { required: true, message: '请输入终端名称', trigger: 'blur' }
        ],
        makerId: [
          { required: true, message: '请选择终端厂家', trigger: 'blur' }
        ],
        model: [{ required: true, message: '请选择终端型号', trigger: 'blur' }],
        type: [{ required: true, message: '请选择终端类型', trigger: 'blur' }],
        cardTemplate: [
          { required: true, message: '请输入写卡模板', trigger: 'blur' }
        ]
      },
      producerList: [],
      modelList: [],
      typeList: []
    }
  },
  methods: {
    // 新增/修改
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            deviceName: this.addForm.deviceName,
            makerId: this.addForm.makerId,
            model: this.addForm.model,
            type: this.addForm.type,
            cardTemplate: this.addForm.cardTemplate
          }
          if (!this.isEdit) {
            addDeviceModel(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            editDeviceModel(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia(flag) {
      this.$emit('close', flag)
    },
    /** 获取数据字典 */
    getDicts() {
      queryDictsByCodes({ parentCodes: 'SBCJ,SBXH,ZDLX' }).then((res) => {
        if (res.code === 1000) {
          this.producerList = res.data.SBCJ
          this.modelList = res.data.SBXH
          this.typeList = res.data.ZDLX
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    if (
      JSON.stringify(this.item) !== '{}' &&
      JSON.stringify(this.item) !== 'null'
    ) {
      this.addForm = { ...this.item }
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-vehicle {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
